import React, { useEffect } from "react"
import Layout from "components/layout"
import SEO from "components/seo"
import OutSideLink from "components/outlink"
import { Stats } from "components"
import "styles/styles.scss"
import {
  StoryDistribution,
  StoryDistributionChart,
} from "components/storyDistribution"
import { InLink } from "components/inlink"

const IndexPage = () => {
  useEffect(() => {
    //Check Added Becaouse SSR not include window
  }, [])

  return (
    <Layout>
      <SEO title="Onur Dayibasi Notes" />

      <div className="main">
        <div className="main-part">
          <div className="main-group">
            <h1>
              <Stats all={true} />
            </h1>

            <div className="sub-group">
              <h2>React</h2>
              <div className="content">
                <InLink to="/react/">React Basics</InLink>
                <InLink to="/react-hooks/">React Hooks</InLink>
                <InLink to="/react-router/">React Routing</InLink>
                <InLink to="/react-network/">React Network</InLink>
                <InLink to="/react-state/">React State</InLink>
                <InLink to="/react-webpack/">React Bundler</InLink>
                <InLink to="/react-server-components/">
                  React Server Components
                </InLink>
                <InLink to="/react-performance/">React Performance</InLink>
                <InLink to="/web-test-performans/">React Test</InLink>
                <InLink to="/react-npm-package/">React Modules</InLink>
                <InLink to="/react-ui-styling/">React Styling</InLink>
                <InLink to="/visualization-and-charts/">
                  React Visualization
                </InLink>
                <InLink to="/urun-ve-kutuphaneler/">React Libs</InLink>
              </div>
            </div>

            <div className="sub-group">
              <h2>React Advanced</h2>
              <InLink to="/react-knowledge-maps/">React Knowledge Maps</InLink>
              <InLink to="/react-ui-mechanics/">React UI Mechanics</InLink>
              <InLink to="/react-architecture/">React Architecture</InLink>
              <InLink to="/react-microfrontends/">React Microfrontends</InLink>
              <InLink to="/react-lab/">React Digital Garden</InLink>
            </div>

            <div className="sub-group">
              <h2>JavaScript</h2>
              <div className="content">
                <InLink to="/javascriptin-tarihçesi/">
                  JS Tarihçe ve Ekosistem
                </InLink>
                <InLink to="/javascript-usage/">JS Practice</InLink>
                <InLink to="/javascript-async-programming/">
                  JS Async Programming
                </InLink>
                <InLink to="/javascript-metaprogramming/">
                  JS Meta Programming
                </InLink>
                <InLink to="/javascript-deep-learning/">
                  JS Derinlemesine Öğren
                </InLink>
                <InLink to="/typescript-basics/">JS-TypeScript</InLink>
                <InLink to="/javascript-ile-fonksiyonel-programlama/">
                  JS Fonksiyonel Programlama
                </InLink>
                <InLink to="/arduino/">JS Arduino Programming</InLink>
              </div>
            </div>

            <div className="sub-group">
              <h2>Frontend</h2>
              <div className="content">
                <InLink to="/frontend-ecosystem/">Frontend Ekosistem</InLink>
                <InLink to="/frontend-ideas/">Frontend Düşünceleri</InLink>
                <InLink to="/gelistirici-araclari/">
                  Frontend Geliştirici Araçları
                </InLink>
                <InLink to="/faydali-blog-analizleri/">
                  Frontend Blog Analizleri
                </InLink>
              </div>
            </div>

            <div className="sub-group">
              <h2>Temel Kavramlar</h2>
              <div className="content">
                <InLink to="/internet-kavramlari/">İnternet Kavramları</InLink>
                <InLink to="/chrome-nasil-calisir/">
                  Chrome Nasıl Çalışır
                </InLink>
                <InLink to="/dom-api-kullanimi/">DOM API Kullanımı</InLink>
                <InLink to="/web-api-kullanimi/">Web API Kullanımı</InLink>
                <InLink to="/rendering-techniques/">
                  Rendering Teknikleri
                </InLink>
              </div>
            </div>

            <div className="sub-group">
              <h2>Algoritma</h2>
              <div className="content">
                <InLink to="/algoritma-veriyapisi/">
                  Algoritma - Veri Yapısı
                </InLink>
                <InLink to="/dogal-dil-isleme/">
                  Algoritma - Doğal Dil İşleme
                </InLink>
                <InLink to="/game-and-bi/">Algoritme - Oyun Geliştirme</InLink>
              </div>
            </div>

            <div className="sub-group">
              <h2>Yazılım Mimarisi</h2>
              <div className="content">
                <InLink to="/mimari-kavramlar/">Mimari Kavramlar</InLink>
                <InLink to="/mimari-deneyim/">Mimari Deneyim</InLink>
                <InLink to="/mimari-kalite/">Mimari Kalite</InLink>
                <InLink to="/mimari-oruntuler/">Mimari Örüntüler</InLink>
                <InLink to="/oop-tasarim-oruntuleri/">
                  Tasarım Örüntüleri
                </InLink>
                <InLink to="/antipatterns/">Mimari Anti-Patterns</InLink>
              </div>
            </div>

            <div className="sub-group">
              <h2>Diğer Yazılarım ve Backend</h2>
              <div className="content">
                <InLink to="/aws/">Web Servisleri (AWS)</InLink>
                <InLink to="/uix-design/">UIX ve Tasarım</InLink>
                <InLink to="/fe-interview/">Interview(Mülakat)</InLink>
              </div>
            </div>

            <div className="sub-group">
              <h2>Projeler</h2>
              <div>
                <OutSideLink
                  title={"Yumod- (Medium Chrome Extension)"}
                  url={`https://github.com/odayibasi/yumod-chrome-extension`}
                />
                <OutSideLink
                  title={"SWEngine (2 Boyutlu Oyun Motoru)"}
                  url={`https://github.com/odayibasi/swengine`}
                />
                <OutSideLink
                  title={"SWEngine için Örnekler"}
                  url={`https://github.com/odayibasi/swengine/tree/master/samples`}
                />
                <div>
                  <InLink to="/tutorials/2020/2020-03-29-mdx-ve-dinamik-sayfalar/">
                    MDX and Dynamic Pages
                  </InLink>
                </div>
                <div>
                  <InLink to="/tutorials/2021/2021-04-10-videos/">
                    Proje ve Etkinlikler ile İlgili Videoar
                  </InLink>
                </div>
              </div>
            </div>
          </div>

          <div className="sub-group">
            <h2>Updates</h2>
            <div className="content">
              <InLink to="/learn-react-ui-dev-news/">
                LearnReactUI.dev Updates
              </InLink>
              <InLink to="/note-updates/">OnurDayibasi.com Updates</InLink>
              <InLink to="/react-digital-garden-news/">
                OnurDayibasi.dev Updates
              </InLink>
            </div>
          </div>
        </div>
        <div className="ad-part">
          <StoryDistributionChart data={StoryDistribution} />
        </div>
      </div>
    </Layout>
  )
}

export default IndexPage
